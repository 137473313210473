// src/components/AboutSection.tsx

import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.section`
  background-color: #1e1e1e;
  color: #f5f5f5;
  padding: 80px 20px;
  text-align: center;
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: 'Your Chosen Font', serif;
`;

const AboutContent = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const EmailLink = styled.a`
  color: #d4af37;
  text-decoration: none;

  &:hover {
    color: #b38e2e;
    text-decoration: underline;
  }
`;

const AboutSection: React.FC = () => {
  return (
    <AboutContainer>
      <AboutTitle>About Us</AboutTitle>
      <AboutContent>
        Based in the heart of Fayetteville, Arkansas, we are a dedicated team passionate about promoting exceptional liquor brands. Our mission is to introduce customers to unique and lesser-known spirits through engaging in-store experiences. By offering free samples at liquor stores, we create opportunities for customers to discover new favorites and for brands to reach a wider audience.

        With a robust team capable of covering the states of Arkansas, Missouri, and Oklahoma, we specialize in driving sales and increasing brand visibility. Our approach not only boosts immediate sales but also fosters long-term customer loyalty and brand recognition. We pride ourselves on our personalized touch, industry expertise, and commitment to delivering outstanding results for both our clients and partner retailers.

        <br /><br />
        Contact us at{' '}
        <EmailLink href="mailto:ashley@highspiritspromo.com">
          ashley@highspiritspromo.com
        </EmailLink>
      </AboutContent>
    </AboutContainer>
  );
};

export default AboutSection;
