// src/pages/GalleryPage.tsx

import React from 'react';
import Layout from '../components/Layout';
import GallerySection from '../components/GallerySection';

const GalleryPage: React.FC = () => {
  return (
    <Layout>
      <GallerySection />
    </Layout>
  );
};

export default GalleryPage;
