// src/components/Footer.tsx

import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #121212;
  color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
`;

const EmailLink = styled.a`
  color: #d4af37; /* Gold color */
  text-decoration: none;

  &:hover {
    color: #b38e2e;
    text-decoration: underline;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>&copy; {new Date().getFullYear()} High Spirits Promotions. All rights reserved.</FooterText>
      <FooterText>
        Contact us at{' '}
        <EmailLink href="mailto:ashley@highspiritspromo.com">
          ashley@highspiritspromo.com
        </EmailLink>
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
