// src/components/GallerySection.tsx

import React from 'react';
import styled from 'styled-components';
import BottleCard from './BottleCard'; // Import your BottleCard component

const GalleryContainer = styled.section`
  background-color: #1e1e1e;
  color: #f5f5f5;
  padding: 60px 20px;
  text-align: center;
`;

const GalleryTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  font-family: 'Your Chosen Font', serif;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const GallerySection: React.FC = () => {
  // Placeholder data for gallery items
  const bottles = [
    {
      image: '/bottle1.jpg',
      title: 'Whiskey Reserve',
      description: 'Aged to perfection with rich flavors.',
    },
    {
      image: '/bottle2.jpg',
      title: 'Vodka Supreme',
      description: 'Smooth and crisp with a clean finish.',
    },
    {
      image: '/bottle3.jpg',
      title: 'Golden Rum',
      description: 'Sweet and spicy, perfect for cocktails.',
    },
    // Add more items as needed
  ];

  return (
    <GalleryContainer>
      <GalleryTitle>Our Spirits Collection</GalleryTitle>
      <GalleryGrid>
        {bottles.map((bottle, index) => (
          <BottleCard
            key={index}
            image={bottle.image}
            title={bottle.title}
            description={bottle.description}
          />
        ))}
      </GalleryGrid>
    </GalleryContainer>
  );
};

export default GallerySection;
