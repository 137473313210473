// src/components/BottleCard.tsx

import React from 'react';
import styled from 'styled-components';

interface BottleCardProps {
  image: string;
  title: string;
  description: string;
}

const Card = styled.div`
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    color: #f5f5f5;
    margin: 10px 0;
  }

  p {
    color: #cccccc;
    padding: 0 10px 20px;
  }
`;

const BottleCard: React.FC<BottleCardProps> = ({ image, title, description }) => {
  return (
    <Card>
      <img src={image} alt={title} />
      <h3>{title}</h3>
      <p>{description}</p>
    </Card>
  );
};

export default BottleCard;
