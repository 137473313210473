// src/components/HeroSection.tsx

import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
  position: relative;
  text-align: center;
  color: #f5f5f5;
`;

const HeroImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 800px;
  object-fit: cover;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroText = styled.div`
  font-family: 'Your Chosen Font', serif;
  color: #f5f5f5;
  text-align: center;
`;

const HeroHeading = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 20px;
`;

const HeroSubheading = styled.p`
  font-size: 1.5rem;
`;

const HeroSection: React.FC = () => {
  return (
    <HeroContainer>
      <HeroImage src="/home_image_1.png" alt="Promotional image" />
      <HeroOverlay>
        <HeroText>
          <HeroHeading>High Spirits Promotions</HeroHeading>
          <HeroSubheading>Elevating Brands Through Engaging Experiences</HeroSubheading>
        </HeroText>
      </HeroOverlay>
    </HeroContainer>
  );
};

export default HeroSection;
