// src/components/Header.tsx

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  background-color: #121212;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;

const StyledNavLink = styled(NavLink)`
  color: #f5f5f5;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: 'Your Chosen Font', serif;
  padding: 10px 15px;
  border-radius: 4px;

  &.active,
  &:hover {
    background-color: #d4af37; /* Gold color */
    color: #121212;
  }
`;

const ContactButton = styled.a`
  background-color: #d4af37; /* Gold color */
  color: #121212;
  font-size: 1.2rem;
  font-family: 'Your Chosen Font', serif;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background-color: #b38e2e;
  }
`;

const Header: React.FC = () => {
  return (
    <Nav>
      <NavContainer>
        <StyledNavLink to="/">Home</StyledNavLink>
        <StyledNavLink to="/gallery">Gallery</StyledNavLink>
        <StyledNavLink to="/about">About</StyledNavLink>
        <ContactButton href="mailto:ashley@highspiritspromo.com">Contact</ContactButton>
      </NavContainer>
    </Nav>
  );
};

export default Header;
