// src/pages/AboutPage.tsx

import React from 'react';
import Layout from '../components/Layout';
import AboutSection from '../components/AboutSection';
import { Helmet } from 'react-helmet-async';

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>About Us - High Spirits Promotions</title>
        <meta name="description" content="Learn about High Spirits Promotions, a team dedicated to promoting exceptional liquor brands through engaging in-store experiences." />
      </Helmet>
      <AboutSection />
    </Layout>
  );
};

export default AboutPage;
