// src/pages/HomePage.tsx

import React from 'react';
import Layout from '../components/Layout';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import { Helmet } from 'react-helmet-async';

const HomePage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>High Spirits Promotions - Home</title>
        <meta name="description" content="Discover unique liquor brands through High Spirits Promotions, bringing tasting events and engaging experiences across Arkansas, Missouri, and Oklahoma." />
        <meta name="keywords" content="liquor promotions, spirit tastings, Arkansas liquor events, High Spirits Promotions" />
      </Helmet>
      <HeroSection />
      <AboutSection />
    </Layout>
  );
};

export default HomePage;
